import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../layouts'

import people from "../../data/people.yaml"

import AboutSidenav from "../../partials/about-sidenav"

import '../../scss/about.scss'

const AboutManagementPage = ({data}) => {
  return (
    <Layout>
      <div className="about key-researchers">

      <Helmet>
        <title>DigiWells – Key researchers</title>
        <meta property="og:title" content="DigiWells – Key researchers" />
        <meta name="description" content="DigiWells key researchers." />
        <meta property="og:description" content="DigiWells key researchers." />
        {
          /*
          <meta name="image" content={image} />
          <meta property="og:image" content={image} />
          */
        }
      </Helmet>

        <div className="container">
          <AboutSidenav />

          <div className="about-content">
            <h1 className="page-title">Key researchers</h1>

            <div className="contact">
            {
              people.keyResearchers &&
              people.keyResearchers.map(function(keyResearchersGroup) {
                return (
                  <>
                    <h2>{keyResearchersGroup.groupName}</h2>
                    <div className="people smaller">
                      {
                        keyResearchersGroup.people &&
                        keyResearchersGroup.people.sort(function(a, b){
                          if(a.name < b.name) { return -1; }
                          if(a.name > b.name) { return 1; }
                          return 0;
                        }).map(function(person) {
                          if (person.url){
                            return (
                              <div className="person-card">
                                <div className="person-text">
                                  <p className="person-name">
                                    <a href={person.url} target="_blank" rel="noreferrer">{person.name}
                                    <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="24px"  viewBox="0 -2 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                    </a>
                                  </p>
                                  <p>{person.workTitle}</p>
                                  <p>{person.email}</p>
                                </div>
                              </div>
                            )
                          }
                          else if (keyResearchersGroup.groupName == "NORCE"){
                            return (
                              <div className="person-card">
                                <div className="person-text">
                                  <p className="person-name">
                                    <a target="_blank" rel="noreferrer" href={"https://www.norceresearch.no/en/persons/"+person.name.replace(/\s+/g, '-').toLowerCase()}>
                                      {person.name}
                                      <svg className="icon" xmlns="http://www.w3.org/2000/svg" height="24px"  viewBox="0 -2 24 24" fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                    </a>
                                  </p>
                                  <p>{person.workTitle}</p>
                                  <p>{person.email}</p>
                                </div>
                              </div>
                            )
                          }
                          return (
                            <div className="person-card">
                              <div className="person-text">
                                <p className="person-name">{person.name}</p>
                                <p>{person.workTitle}</p>
                                <p>{person.email}</p>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </>
                )
              })
            }
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default AboutManagementPage
